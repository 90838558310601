<template>
  <div class="media">
    <icon class="media-left" width="20" height="20" :icon="icon"/>
    <div class="media-content">
      <span>{{ text }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.media-left {
  margin-right: 0.5rem;
}

</style>

<script>

import Icon from '@/components/Icons/Icon'

export default {
  components: { Icon },
  name: 'DropdownItemContent',
  props: ['text', 'icon']
}

</script>
