<template>
  <div :class="{'is-title-bar': true, 'mobile-app-header-padding': isMobileApp }">
    <div :class="{ box: true, 'main-page-header-scoped': true, 'setup-complete': isSetupComplete }">
      <div class="columns is-mobile fit-title-green-line">
        <div class="column is-12 green-line is-hidden-touch"></div>
      </div>
      <div class="level has-padding-tb has-mobile-padding-tb no-margin-bottom fit-line is-mobile">
        <div class="level-left">
          <div class="level-item is-hidden-touch">
            <h1 class="rubik">
              <slot/>
            </h1>
          </div>
          <div class="level-item is-hidden-desktop" v-on:click="toggleNav">
            <icon icon="hamburger" width="20" height="16" fill="#2D3958"/>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <b-field class="header-search" expanded v-if="false">
              <b-input placeholder="Search..." type="search" icon="magnify" expanded>
              </b-input>
            </b-field>
            <b-dropdown aria-role="list">
              <a class="button is-big is-primary" slot="trigger">Create New...</a>
              <b-dropdown-item aria-role="listitem" has-link v-for="createMenuItem in createMenuItems" v-bind:key="createMenuItem.route">
                <router-link :to="{ name: createMenuItem.route }">
                  <dropdown-item-content :text="createMenuItem.text" :icon="createMenuItem.icon"></dropdown-item-content>
                </router-link>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Icon from '@/components/Icons/Icon'
import DropdownItemContent from '@/components/DropdownItemContent'

export default {
  name: 'TitleBar',
  components: { Icon, DropdownItemContent },
  computed: {
    isMobileApp () {
      return process.env.NODE_ENV === 'mobile'
    },
    isSetupComplete () {
      return this.$store.getters['account/isSetupComplete']
    }
  },
  data () {
    return {
      createMenuItems: [
        { route: 'clients-create', text: 'Client', icon: 'client' },
        { route: 'suppliers', text: 'Supplier', icon: 'package' },
        { route: 'quotes-create', text: 'Quote', icon: 'clipboard' },
        { route: 'invoices-create', text: 'Invoice', icon: 'accounting' },
        { route: 'expenses', text: 'Expense', icon: 'receipt' }
      ]
    }
  },
  methods: {
    createClient () {
      this.$router.push({ name: 'clients-create' })
    },
    createQuote () {
      this.$router.push({ name: 'quotes-create' })
    },
    createInvoice () {
      this.$router.push({ name: 'invoices-create' })
    },
    toggleNav () {
      this.$store.commit('asideMobileStateToggle')
    }
  }
}
</script>

<style lang="scss" scoped>
.has-padding-tb {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px !important;
}

.fit-title-green-line {
  margin: 0px 10px;
}

.header-search {
  margin-bottom: 0px;
  margin-right: .75rem;
}

.setup-complete {
  height: 120px;
}

.dropdown-item-text {
  margin-left: 4px;
}

.mobile-app-header-padding {
  padding-top: 40px;
}

@media only screen and (min-width: 1024px) {
  .has-padding-tb {
  margin-top: 9px !important;
  padding-left: 10px;
  padding-right: 10px;
}
}

@media only screen and (min-width: 320px) and (max-width: 880px) {
  /*MARC dropping button fix*/
  .button.is-primary {
    margin-top: 0px;
  }
}

</style>
